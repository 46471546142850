h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: var(--surface-900);

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #fff8e1;
  padding: 0.25rem 0.4rem;
  border-radius: $borderRadius;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90a4ae;
}

hr {
  border-top: solid lightgray;
  border-width: 1px 0 0 0;
  margin: 10px 0;
}

p {
  margin: 0;
  line-height: 1.5;

  &:last-child {
    margin-bottom: 0;
  }
}

.poppins-500 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-400 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-300 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.font-20 {
  font-size: 20px;
}

.font-19 {
  font-size: 19px;
}

.font-18 {
  font-size: 18px;
}

.font-17 {
  font-size: 17px;
}

.font-16 {
  font-size: 16px;
}

.font-15 {
  font-size: 15px;
}
.font-14 {
  font-size: 14px;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

.font-11 {
  font-size: 11px;
}

.font-10 {
  font-size: 12px;
}
